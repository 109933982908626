function Footer() {
  return (
    <footer>
      <p>
        © 2022 Benko av Bente Herland. Alle rettigheter forbeholdt. | Org.nr 925
        931 497
      </p>
    </footer>
  );
}

export default Footer;
